import React, { useState, useEffect } from "react";
import Cctv from "./cctv/cctv.js";
import Notes from "./notes/notes.js";
import "./investigation.css";

function Investigation(props) {
  const [myAnswer, setMyAnswer] = useState([-1, -1]);

  useEffect(() => {
    if (parseInt(props.day.day) === 5) {
      setMyAnswer([-1]);
    }
  }, [props.day.day]);

  return (
    <div className={props.showInvestigation ? "Investigation" : ""}>
      {props.showInvestigation && (
        <>
          <div className="Cctv">
            <Cctv
              className="Cctv"
              data={props.data}
              background={props.background}
              object={props.object}
              suggestion1={props.suggestion1}
              suggestion2={props.suggestion2}
              day={props.day}
              myAnswer={myAnswer}
              setMyAnswer={setMyAnswer}
              clueState={props.clueState}
              setClueState={props.setClueState}
              func={props.func}
            />
          </div>

          <Notes
            className="Notes"
            notes={props.data.notes}
            func={props.func}
            myAnswer={myAnswer}
            setMyAnswer={setMyAnswer}
            answer={props.data.answer}
          />
        </>
      )}
    </div>
  );
}

export default Investigation;