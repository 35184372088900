import React, { useState, useEffect } from "react";
import "./notes.css";

const Notes = (props) => {
  const [allowClick, setAllowClick] = useState(false);
  const [showError, setShowError] = useState(false);
  const [notes, setNotes] = useState([]);

  const resetNote = (noteIndex) => {
    props.setMyAnswer(prevMyAnswer => {
      // update the corresponding note value in myAnswer to -1
      const updatedMyAnswer = [...prevMyAnswer];
      updatedMyAnswer[noteIndex] = -1;
      return updatedMyAnswer;
    });
  };

  const handleClick = () => {
    // check if the user's answer matches the correct answer
    const isCorrect = props.myAnswer.every((noteIndex, idx) => {
      return noteIndex === props.answer[idx];
    });
    if (isCorrect) {
      props.func();
    } else {
      setShowError(true);
    }
  };

  useEffect(() => {
    // update notes and allowClick state whenever myAnswer changes
    const notes = props.myAnswer.map((noteIndex, idx) => {
      return noteIndex !== -1 ? props.notes[idx][noteIndex - 1] : null;
    });
    setNotes(notes);

    const isAnswerComplete = props.myAnswer.every(noteIndex => {
      return noteIndex !== -1;
    });
    setAllowClick(isAnswerComplete);
  }, [props.myAnswer, props.notes]);

  return (
    <div className="Notes">
      <div className="Note">
        {notes.map((note, idx) => {
          return (
            <p key={idx} onClick={() => resetNote(idx)}>
              {note}
            </p>
          );
        })}
      </div>
      <button
        onClick={handleClick}
        disabled={!allowClick}
        style={!allowClick ? { backgroundColor: "#ccc", cursor: "default" } : {}}
      >
        continue
      </button>
      {showError && (
        <p>
          Umm… Maybe I guessed the wrong answer. I should consider the clues
          again.
        </p>
      )}
    </div>
  );
};

export default Notes;